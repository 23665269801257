import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TablePagination, Switch, capitalize } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import MaterialTable, { MTableToolbar } from "material-table";
import classNames from "classnames";
import { Edit } from "@material-ui/icons";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { GET_FUNCTIONS_LIST, GET_INDUSTRIES_LIST, GET_LANGUAGE, GET_NUDGES_CATEGORY_LIST, MASTER_TEMPLATES_LIST, MASTER_TEMPLATE_STATUS, WHATSAPP_EVENTS } from "../config";
import MasterTemplateDialog from "./MasterTemplateDialog";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import { setLoading } from "../ducks/loading";
import Axios from "axios";
import ChooseTemplates from "./Templates/ChooseTemplates";
import WhatsappTemplateDialog from "./Templates/WhatsappTemplateDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiIconButton-root": {
            "&:hover": { background: "none" },
        },
    },
    wrapper: {
        margin: "16px 20px 20px",
    },
    footerButton: {
        margin: 18,
        width: 135,
        height: 47,
    },
    button: {
        color: "white",
        padding: "5px",
        textTransform: "capitalize",
        height: "37px !important",
        width: 180,
        // marginTop: 7,
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: "3px 7px !important",
    },
    selectionType: {
        "& .MuiFormControl-root": { "& label": { top: "0px" } },
    },
    customToolbarRoot: {
        position: "absolute",
        top: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        right: 0,
        "& .MuiInputBase-input": {
            // height: 15
        },
    },
    tableRoot: {
        // width: '80%',
        // margin: "0 auto",
    },
    templates_row: {
        display: "inline",
        "& h6": {
            margin: 0,
            fontSize: 13,
        },
        "& p": {
            margin: 0,
            fontSize: 13,
        },
    },
}));

let total_count = 0;
let total_pages = 0;


const Templates = (props) => {
    const myTextIcon = React.useRef(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [editType, setEditType] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [chooseTemplatesDialog, setChooseTemplatesDialog] = useState(false);
    const [openWhatsappDialog, setOpenWhatsappDialog] = useState(false);
    const [languageList, setLanguageList] = useState([]);
    const [whatsAppEvents, setWhatsAppEvents] = useState([]);



    useEffect(() => {
        fetchTemplates();
    }, [currentPage, pageSize]);


    useEffect(() => {
        getIndustrysList();
        getFunctionsList();
        getCategoryList();
        getLanguage();
        getWhatsappEventsList();
    }, []);



    // -------fetch list templates---
    const fetchTemplates = () => {
        props.setLoading(true);
        Axios.get(MASTER_TEMPLATES_LIST(), {
            params: {
                page: currentPage,
                limit: pageSize,
            },
        })
            .then((res) => {
                // ----set total count and pages ---
                total_count = res.headers.total_count;
                total_pages = res.headers.total_pages;
                let data = res.data.map((el) => {
                    return {
                        ...el,
                        category_name: el?.category?.name,
                        event_name: el?.event?.name
                    }
                })
                setTemplateList(data);
                props.setLoading(false);
            })
            .catch((err) => {
                props.setLoading(false);
                enqueueSnackbar("Failed to fetch Templates", {
                    variant: "error",
                });
            });
    };

    // -----get industries lists -----
    const getIndustrysList = () => {
        setLoading(true);
        Axios.get(`${GET_INDUSTRIES_LIST()}`, { params: { page: 0, limit: 500 } })
            .then(response => {
                let data = response.data.map((el) => {
                    return {
                        name: el.name,
                        id: el._id,
                        ...el
                    }
                })
                setIndustries(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.message : 'Failed to fetch Industries.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    // -----get functions lists -----
    const getFunctionsList = () => {
        setLoading(true);
        Axios.get(`${GET_FUNCTIONS_LIST()}`, { params: { page: 0, limit: 500 } })
            .then(response => {
                let data = response.data.map((el) => {
                    return {
                        name: el.name,
                        id: el._id,
                        ...el
                    }
                })
                setFunctions(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.message : 'Failed to fetch Functions.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            });
    }

    //-----------  Get Language --------
    const getLanguage = () => {
        setLoading(true)
        Axios.get(GET_LANGUAGE, { params: {active:true, page: 0, limit: 1000 } }).then(languages => {
            let data = languages.data.map((el) => {
                return {
                    name: el.name,
                    id: el._id,
                    ...el
                }
            })
            setLanguageList(data)
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Language', {
                variant: 'error',
                preventDuplicate: true
            });
        })
    }

    //-----get events lists -----
    const getWhatsappEventsList = () => {
        setLoading(true);
        Axios
            .get(WHATSAPP_EVENTS(), { params: { page: 0, limit: 1000 } })
            .then(response => {
                let data = response.data.map((el) => {
                    return {
                        name: el?.class,
                        id: el._id,
                        ...el
                    }
                })
                setWhatsAppEvents(data);
                setLoading(false);
            })
            .catch(error => {
            });
    }

    // ------------fetch categorys list ----
    const getCategoryList = () => {
        Axios.get(GET_NUDGES_CATEGORY_LIST(), {
            params: {
                page: 0, limit: 500
            },
        })
            .then((res) => {
                if (res) {
                    let data = res.data.map((el) => {
                        return {
                            name: el?.title,
                            id: el._id,
                            ...el
                        }
                    })
                    setLoading(true);
                    setCategoryList(data);
                }
            });
    };

    // -------switch toggle status changes api---
    const handleToggleChange = (e, value, rowData) => {
        if (rowData) {
            props.setLoading(true);
            Axios
                .put(MASTER_TEMPLATE_STATUS(rowData._id), { active: value })
                .then((res) => {
                    fetchTemplates();
                    setSelectedCategory(null);
                    enqueueSnackbar(
                        value
                            ? "Template activated successfully."
                            : "Template deactivated successfully.",
                        { variant: "success" }
                    );
                })
                .catch((err) => {
                    enqueueSnackbar(
                        value
                            ? "Failed to activate Template."
                            : "Failed to deactivated Template.",
                        { variant: "error" }
                    );
                    props.setLoading(false);
                });
        }
    };


    // -----page on change ----
    const handlePageChange = (data, value, newPageNumber) => {
        setCurrentPage(value == total_pages ? value : value);
    };
    const handleRowsPerPageChange = (event) => {
        setPageSize(event.target.value);
        setCurrentPage(0);
    };

    // ----add edit template dialogs -----
    const handleChooseClick = (type) => {
        setChooseTemplatesDialog(false);
        if (type === 'BUZZ') setOpenTemplateDialog(true);
        else if (type === 'WHATSAPP') setOpenWhatsappDialog(true);
    }
    const handleCloseChooseTemplate = (value) => {
        setChooseTemplatesDialog(false);
    };
    const handleCloseTemplateDialog = (value) => {
        if (value) fetchTemplates();
        setOpenTemplateDialog(false);
        setSelectedTemplate(null);
        setSelectedCategory(null);
        setOpenWhatsappDialog(false);
        if (editType) setEditType(null);
    };
    const handleOpenTemplateDialog = () => {
        setChooseTemplatesDialog(true);
        // setOpenTemplateDialog(true);
        // setTimeout(() => {
        //   if (!editType) myTextIcon.current.click();
        // });
    };
    const handleEditClick = (rowData) => {
        setSelectedTemplate(templateList.find((obj) => obj._id === rowData._id));
        (rowData.channel === 'WHATSAPP') ? setOpenWhatsappDialog(true) : setOpenTemplateDialog(true);
        setEditType(true);
    };


    const column = [
        {
            title: "Event",
            field: "event_name",
            editable: false,
            cellStyle: { minWidth: 140 },
        },
        {
            title: "Event Category",
            field: "category_name",
            editable: false,
            cellStyle: { minWidth: 140 },
        },
        {
            title: "Template Type",
            field: "channel",
            editable: false,
            cellStyle: { minWidth: 100 },
            render: (rowData) => rowData?.channel ? (rowData?.channel.toUpperCase() === 'NUDGE' ? 'Buzz' : capitalize(rowData?.channel.toLowerCase())) : '--'
        },
        {
            title: "Buzz Template",
            field: "title",
            editable: false,
            cellStyle: { minWidth: 450 },
            render: (rowData) => (
                <div className={classes.templates_row}>
                    <h6>{rowData?.title}</h6>
                    <p>{rowData?.message}</p>
                </div>
            ),
        },
        {
            title: "Active",
            width: 80,
            field: "active",
            editable: false,
            render: (rowData) => (
                <Switch
                    checked={rowData && rowData.active}
                    color="primary"
                    name="active"
                    size="small"
                    onChange={(e, props) => handleToggleChange(e, props, rowData)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                />
            ),
        },
    ];

    const EnhancedTableToolbar = () => {
        const classes = useStyles();
        return (
            <div>
                <div className={classes.customToolbarRoot}>
                    {/* <div
                        className={classes.selectionType}
                        style={{ width: "200px", marginRight: "8px" }}
                    >
                        <Autocomplete
                            clearable
                            id="posttype"
                            autoComplete
                            options={categoryList}
                            getOptionLabel={(option) => option.name}
                            value={selectedCategory}
                            classes={classes.autocompleteRoot}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Category"
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                            onChange={(e, val) => setSelectedCategory(val)}
                        />
                    </div> */}
                    <div>
                        <Button
                            {...props}
                            color="primary"
                            variant="contained"
                            disableElevation
                            disableRipple
                            className={classes.button}
                            onClick={() => handleOpenTemplateDialog()}
                        >
                            + Create Template
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
    const breadcrumbsList = [
        { label: 'Platform Admin', to: '/platform-admin' },
        { label: 'Buzz Settings' }
    ];

    return (
        <div className={classes.root}>
            <div className={"common-wrapper"}>
                <CustomBreadcrumbs
                    title={"Message Templates"}
                    crumbsList={breadcrumbsList}
                />
                <div className={classNames("material-table", classes.tableRoot)}>
                    <MaterialTable
                        key={templateList.length}
                        title="List Of flows"
                        icons={{
                            Add: (props) => (
                                <div ref={myTextIcon} style={{ marginLeft: "5px" }}>
                                </div>
                            ),
                        }}
                        columns={column}
                        data={templateList}
                        options={{
                            actionsColumnIndex: -1,
                            showTitle: false,
                            addRowPosition: "first",
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [10, 25, 50],
                            search: true,
                            searchFieldAlignment: "left",
                            searchFieldStyle: {
                                zIndex: 1,
                                marginLeft: -20,
                                width: 200,
                                top: 5,
                            },
                            paging: true,
                            pageSize: pageSize,
                        }}
                        actions={[
                            {
                                icon: () => <Edit style={{ fontSize: "20px" }} />,
                                tooltip: "edit",
                                onClick: (event, rowData) => handleEditClick(rowData),
                            }
                        ]}
                        components={{
                            Pagination: (props) => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 50]}
                                    rowsPerPage={pageSize}
                                    count={total_count} //total count
                                    page={currentPage} //page number 0,1,2,3
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                />
                            ),
                            Toolbar: (props) => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <EnhancedTableToolbar {...props} />
                                </div>
                            ),
                        }}
                        editable={{
                            onRowAdd: (newData) => false,
                            isEditable: () => false,
                        }}
                    ></MaterialTable>
                </div>
                {openTemplateDialog && (
                    <MasterTemplateDialog
                        isEdit={selectedTemplate}
                        open={openTemplateDialog}
                        handleClose={handleCloseTemplateDialog}
                        setLoading={setLoading}
                        editType={editType}
                        selectedTemplate={selectedTemplate}
                        categoryList={categoryList}
                        industries={industries}
                        functions={functions}
                    />
                )}
                {openWhatsappDialog && (
                    <WhatsappTemplateDialog
                        isEdit={selectedTemplate}
                        open={openWhatsappDialog}
                        handleClose={handleCloseTemplateDialog}
                        setLoading={setLoading}
                        editType={editType}
                        selectedTemplate={selectedTemplate}
                        categoryList={categoryList}
                        industries={industries}
                        functions={functions}
                        languageList={languageList}
                        whatsAppEvents={whatsAppEvents}
                    />
                )}
                {
                    chooseTemplatesDialog && (
                        <ChooseTemplates
                            open={chooseTemplatesDialog}
                            handleClose={handleCloseChooseTemplate}
                            handleChooseClick={handleChooseClick}
                        />
                    )
                }
            </div>
        </div>
    );
};


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Templates));

