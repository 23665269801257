import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button, Checkbox, Link, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withRouter } from "react-router-dom";
import { setLoading } from "../../ducks/loading";
import { ReactComponent as ReactDragIcon } from "../../assets/icons/drag.svg";
import { ReactComponent as ReactEyeIcon } from "../../assets/icons/eyeopen.svg";
import { ReactComponent as ReactEyecloseIcon } from "../../assets/icons/eyeclose.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Check, Close, Edit } from "@material-ui/icons";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { accumulators, noofTRans, orderbyElements, tableRange } from "./TransactionStepper";
import MaterialTable, { MTableToolbar } from "material-table";
import classNames from "classnames";
import SettingsTablePreview from "./SettingsTablePreview";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: "35px",
        marginRight: "auto",
        marginLeft: "auto",
        // backgroundColor: '#FFF',
        borderRadius: 8,
    },
    rootAuto: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input': {
            padding: '6.5px 4px'
        },
        '& .MuiFormControl-root label': {
            top: '2px'
        },
        '& .MuiFormControl-root label.MuiInputLabel-shrink': {
            top: 0
        }
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    elementsListClass: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 4px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: 8,
        '& .hdng': {
            margin: 0,
            background: '#EBEBEB',
            color: 'rgba(0, 0, 0, 0.65)',
            padding: '12px 10px',
            borderRadius: '8px 8px 0px 0px',
            fontSize: 15,

        },
        '& .elem_style': {
            padding: 10,
            '& .list-container ': {
                textAlign: 'center',
                display: 'flex',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: 14,
                flexDirection: 'column',
                minHeight: 250,
                '& .Lists': {
                    display: 'flex',
                    marginBottom: 10,
                    '& .btn': {
                        minWidth: 40,
                        height: 40,
                        background: 'rgba(0, 0, 0, 0.06)',
                        borderRadius: 4,
                        fontSize: 12,
                        color: '#262626',
                        marginRight: 10,
                        padding: 0
                    },
                    '& .MuiInputBase-root': {
                        width: '170px !important'
                    }
                }
            },
            '& .item-container': {
                border: '1px solid rgba(0, 0, 0, 0.08)',
                borderRadius: 4,
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                height: 40,
                position: 'relative',
                '& .visible': {
                    position: 'absolute',
                    right: 10,
                    cursor: 'pointer'
                },
                '& .visible1': {
                    position: 'absolute',
                    right: 40,
                    cursor: 'pointer'
                }
            },
            '& .item-containers': {
                border: '1px solid rgba(0, 0, 0, 0.08)',
                borderRadius: 4,
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                height: 40,
                position: 'relative',
                background: 'rgba(0, 0, 0, 0.04)',
                '& .visible': {
                    position: 'absolute',
                    right: 10,
                    cursor: 'pointer'
                },
                '& .visible1': {
                    position: 'absolute',
                    right: 40,
                    cursor: 'pointer'
                }
            }
        }
    },
    tableNoteStyles: {
        '& .btn': {
            textTransform: 'capitalize',
            fontSize: 12
        },
        '& .MuiToolbar-root': {
            paddingLeft: 0,
            '& .note': {
                fontSize: 13,
                padding: 0,
                margin: 0
            }
        },
        '& .MuiFormControl-root label': {
            fontSize: '12px !important'
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px !important'
        },
        '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
            background: 'none',
            backgroundColour: 'none'
        }
    },
    autocompleteRoot1: {
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
    textClass: {
        padding: '10px !important',
        '& .MuiOutlinedInput-input': {
            padding: '10px !important'
        },
    },
    optionClass: {
        fontSize: 11
    },
    expresn: {
        position: 'absolute',
        top: '9.5px',
        right: '79px',
        textAlign: 'center',
        alignContent: 'center',
        background: '#c9c9c9',
        padding: '7.5px 9px',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        fontSize: 15,
        cursor: 'pointer'
    },
    autocompleteRoot2: {
        borderRadius: 6,
        padding: '2px 7px !important',
        height: 38,
    },
}));



const TableElementConfig = ({ sortingElements, details, handleDrop, response_elements, objectElementsList, handleSummarizedCreate, handleSummarizedDelete, onElementEditClick, elementDisplayChange, onElementEyeClick, handleElementSubmit, handleElementClose, handleChangeAutocomplete, handleCreate, handleUpdate, handleDelete, handleSummarizedUpdate, handleOpenExpressionDialog, tableRules }) => {
    const classes = useStyles();
    const myTextIcon = React.useRef(null);
    const [isOpenPreviewDailog, setIsOpenPreviewDailog] = useState(false);


    // ---------------Element -------------
    const rerendeElement = useCallback((rowData) => rowData?.elementName ? rowData?.elementName : '--', []);
    const editElement = useCallback((props) => {
        return (
            <Autocomplete
                id="element"
                options={objectElementsList ? objectElementsList : []}
                renderOption={(option) => (
                    <Typography className={classes.optionClass}>{option.name || option?.display_name}</Typography>
                )}
                getOptionLabel={(option) => option?.name || option?.display_name || ''}
                value={props?.value}
                classes={{ inputRoot: classes.autocompleteRoot2 }}
                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, className: 'optionClass' }} style={{ width: 140 }} label='Element' variant="outlined" />}
                onChange={async (...args) => {
                    delete props.rowData['condition'];
                    delete props.rowData['value'];
                    delete props.rowData['buzz_value'];
                    props.onChange(args[1]);
                }}
            />
        )
    }, [])

    // ---------------condition -------------
    const rerendeCondition = useCallback((rowData) => rowData?.conditionName ? rowData?.conditionName : '--', []);
    const editCondition = useCallback((props) => {
        return (
            <Autocomplete
                id="condition"
                options={tableRange}
                renderOption={(option) => (
                    <Typography className={classes.optionClass}>{option.name}</Typography>
                )}
                getOptionLabel={(option) => option?.name || ''}
                value={props?.value}
                classes={{ inputRoot: classes.autocompleteRoot2 }}
                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, className: 'optionClass' }} style={{ width: 110 }} label='Condition' variant="outlined" />}
                onChange={(...args) => {
                    delete props.rowData['value'];
                    delete props.rowData['buzz_value'];
                    props.onChange(args[1]);
                }}
            />
        )
    }, [])
    // ---------------condition -------------

    // ---------------Value -------------
    const preventRerender = useCallback((rowData) => {
        return (
            <div style={{ display: 'flex' }}>
                <p style={{ width: 180, margin: 0 }}>
                    {rowData?.value ? rowData?.value : '--'}
                </p>
                {(tableRules?.rules.length > 1) && <span style={{ paddingLeft: 14, fontWeight: 600, fontSize: 13 }}>AND</span>}
            </div >
        )
    }, []);

    const editComponent = useCallback((props) => {
        return (
            <div className={classes.textClass} style={{ display: 'flex', position: 'relative' }}>
                <TextField
                    value={props?.value ? props?.value : ''}
                    type="text"
                    id="outlined-required"
                    onChange={(e) => {
                        props.onChange(e.target.value)
                    }}
                    variant="outlined"
                    defaultValue=''
                    fullWidth
                    label='Value'
                    disabled={props.rowData?.element?.input_type === 'NA'}
                    min={20}
                    autoComplete="off"
                    style={{ minWidth: 220, maxWidth: 240, padding: '10px important' }}
                />
                {
                    <>
                        <div className={classes.expresn}
                            onClick={(event) => {
                                handleOpenExpressionDialog(props, props?.rowData.value)
                            }}>
                            <p style={{ margin: 0 }}>
                                EX
                            </p>
                        </div>

                    </>

                }
            </div>
        )
    }, []);
    // ---------------Value -------------


    // ----------------summary table column--------------
    const Summarycolumn = [
        {
            title: `Element`,
            field: "element",
            emptyValue: '--',
            editComponent: props => (
                <Autocomplete
                    id="element"
                    options={sortingElements}
                    renderOption={(option) => (
                        <Typography className={classes.optionClass}>{option.name || option.elementName}</Typography>
                    )}
                    getOptionLabel={(option) => option.name || option.elementName}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot2 }}
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, className: 'optionClass' }} style={{ width: 140 }} label='Element' variant="outlined" required />}
                    onChange={(...args) => { props.onChange(args[1]) }}
                />
            ),
            render: (rowData) => rowData?.elementName,
        },
        {
            title: `Summarised By`,
            field: "accumulator",
            emptyValue: '--',
            editComponent: props => (
                <Autocomplete
                    id="summarised"
                    options={accumulators}
                    renderOption={(option) => (
                        <Typography className={classes.optionClass}>{option.name}</Typography>
                    )}
                    getOptionLabel={(option) => option.name || ''}
                    value={props.value}
                    classes={{ inputRoot: classes.autocompleteRoot2 }}
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, className: 'optionClass' }} style={{ width: 140 }} label='Summarised By' variant="outlined" required />}
                    onChange={(...args) => { props.onChange(args[1]) }}
                />
            ),
            render: (rowData) => rowData.accumulatorName,
        }
    ];
    // ----------------filter table column--------------
    const column = [
        {
            title: "Element",
            field: "element",
            editComponent: editElement,
            render: rerendeElement,
        },
        {
            title: "Operator",
            field: "condition",
            editComponent: editCondition,
            render: rerendeCondition,
        },
        {
            title: "Value",
            field: "value",
            editComponent: editComponent,
            render: preventRerender,
        },
    ];


    const closePreviewDailog = () => {
        setIsOpenPreviewDailog(false);
    }

    return (
        <>
            <div className={classes.root}>
                <Grid container justify="center" spacing={2} className={classes.paddingBasicDetails}>

                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            id="groupTrans"
                            autoComplete
                            options={sortingElements}
                            getOptionLabel={(option) => option.name || option.elementName}
                            value={details?.groupTransTable}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Group Table By"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'groupTransTable')}
                        />
                        {/* <Autocomplete
                            id="groupTrans"
                            multiple
                            size="small"
                            options={objectElementsList ? objectElementsList : []}
                            limitTags={3}
                            disableCloseOnSelect
                            getOptionLabel={option => (option?.name || '')}
                            getOptionSelected={(option, value) => option?.element_id === value?.element_id}
                            classes={{ inputRoot: classes.autocompleteRoot1 }}
                            value={details?.groupTransTable ? details?.groupTransTable : []}
                            filterOptions={(options, params) => { // <<<--- inject the Select All option
                                const filter = createFilterOptions()
                                const filtered = filter(options, params)
                                return [{ name: 'Select All', all: true }, ...filtered]
                            }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        className={classes.checkClass}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        // checked={selected}
                                        checked={option.all ? !!(details?.groupTransTable?.length === objectElementsList?.length) : selected}
                                        color="primary"
                                    />
                                    {option?.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label="Group Table By"
                                    size="small"
                                    required
                                />
                            }
                            onChange={(...args) => handleChangeAutocomplete(args[1], 'groupTransTable')}
                        /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <p>Summarize By :</p>
                        <div style={{ marginTop: 0, height: 'auto' }} className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title='summaryTabletable'
                                columns={Summarycolumn}
                                data={response_elements}
                                icons={{
                                    Add: props => (
                                        <div>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                                className="btn"
                                            >
                                                Add Element
                                            </Button>
                                        </div>
                                    )
                                }}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleSummarizedCreate(newData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleSummarizedUpdate(newData, oldData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleSummarizedDelete(oldData, resolve, reject)
                                            return;
                                        })
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <p>Filter Table By :</p>
                        <div style={{ marginTop: 0 }} className={classNames("material-table", classes.tableNoteStyles)}>
                            <MaterialTable
                                title='rulestable'
                                icons={{
                                    Add: props => (
                                        <div ref={myTextIcon}>
                                            <Button
                                                {...props}
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                disableRipple
                                                className="btn"
                                            >
                                                Add New Rule
                                            </Button>
                                        </div>
                                    )
                                }}
                                columns={column}
                                data={tableRules?.rules}
                                options={{
                                    search: false,
                                    actionsColumnIndex: -1,
                                    showTitle: false,
                                    addRowPosition: 'first',
                                    emptyRowsWhenPaging: false,
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div>
                                            <MTableToolbar {...props} />
                                        </div>)
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleCreate(newData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (Object.keys(newData).length > 0) {
                                                handleUpdate(newData, oldData, resolve, reject)
                                                return;
                                            } else {
                                                reject();
                                                return;
                                            }
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(oldData, resolve, reject)
                                            return;
                                        })
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item sm={12} xs={12} md={5}>
                        <Autocomplete
                            id="elementSortingOn"
                            autoComplete
                            options={sortingElements}
                            getOptionLabel={(option) => option.name || option.elementName}
                            value={details?.elementSortingOn}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Element Sorting On"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'elementSortingOn')}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}></Grid>
                    <Grid item sm={12} xs={12} md={5}>
                        <Autocomplete
                            id="elementSortingBy"
                            autoComplete
                            options={orderbyElements}
                            getOptionLabel={(option) => option.name}
                            value={details?.elementSortingBy}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Element Sorting By"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'elementSortingBy')}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}></Grid>
                    <Grid item sm={12} xs={12} md={5}>
                        <Autocomplete
                            id="no_of_records"
                            autoComplete
                            options={noofTRans}
                            getOptionLabel={(option) => option.name}
                            value={details?.no_of_records}
                            type="Schema"
                            clearable
                            classes={{ inputRoot: classes.autocompleteRoot }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="No of Transactions to be displayed"
                                    variant="outlined"
                                    required
                                />
                            )}
                            onChange={(e, val) => handleChangeAutocomplete(val, 'no_of_records')}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}></Grid>
                    <Grid item xs={12}>
                        {details.no_of_records &&
                            <div>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => setIsOpenPreviewDailog(true)}
                                >
                                    Preview
                                </Link>
                            </div>
                        }
                    </Grid>
                    {/* <Grid item xl={5} lg={7} md={7} sm={12} xs={12} className={classes.rootAuto}>
                        <Typography variant="p">Table Heading Orders</Typography>
                        {sortingElements.length > 0 &&
                            <div className={classes.elementsListClass}>
                                <div className="elem_style">
                                    <DragDropContext onDragEnd={handleDrop}>
                                        <Droppable droppableId="list-container">
                                            {(provided) => (
                                                <div
                                                    className="list-container"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {sortingElements.length > 0 && sortingElements?.map((item, index) => (
                                                        <Draggable key={`${item._id}`} draggableId={`${item._id}`} index={index} >
                                                            {(provided) => (
                                                                <div className="Lists" >
                                                                    <Button className='btn'>{index + 1}</Button>
                                                                    <div className="item-container"
                                                                        ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <ReactDragIcon />
                                                                        {item?.name}
                                                                        {
                                                                            editElement?.id === item?.id ?
                                                                                <div style={{ display: 'flex', position: 'relative' }}>
                                                                                    <TextField autoComplete="off" id="display_name" style={{ border: 'none', background: ' none' }} type="text" value={item.name} onChange={elementDisplayChange} />
                                                                                    <div style={{ display: 'flex', position: 'absolute', right: '-30%', top: 7 }}>
                                                                                        <Close style={{ fontSize: "18px", paddingRight: 10 }} onClick={handleElementClose} />
                                                                                        <Check style={{ fontSize: "18px" }} onClick={() => handleElementSubmit(item)} />
                                                                                    </div>
                                                                                </div>: 
                                                                                item.name
                                                                        }
                                                                        {<Edit style={{ fontSize: "18px" }} className="visible1" onClick={() => { onElementEditClick(item) }} />}
                                                                        {item.view ? <ReactEyeIcon className="visible" onClick={() => { onElementEyeClick(item) }} /> : <ReactEyecloseIcon className="visible" onClick={() => { onElementEyeClick(item) }} />}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        }
                    </Grid> */}
                </Grid>
            </div >
            {isOpenPreviewDailog &&
                <SettingsTablePreview
                    open={isOpenPreviewDailog}
                    handleClose={closePreviewDailog}
                    sortingElements={sortingElements}
                    details={details}
                    no_of_records={details.no_of_records}
                />
            }
        </>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(TableElementConfig));
