import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { ReactComponent as BuzzIcon } from "../../assets/icons/buzz-analytics.svg";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    dialogueActions: {
        // display: "flex",
        // justifyContent: "center",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    dialogContent: {
        // minHeight: 430,
        '& .MuiInputLabel-outlined': {
            // top: '-6px !important',
            fontSize: 14
        }
    },
    dialogPaper: {
        width: '100%'
    },
    iconClass: {
        display: 'block',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '& p': {
            margin: 0
        },
        '&:hover': {
            backgroundColor: '#f2f2f2',
            borderRadius: 30
        },
    }
}));



const ChooseTemplates = ({ open = false, handleClose, handleChooseClick }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                Choose New Template
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div style={{ padding: '40px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div className={classes.iconClass} onClick={() => handleChooseClick('BUZZ')}>
                                <BuzzIcon style={{ width: 90, height: 90, opacity: 0.8 }} />
                                <p>Buzz</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.iconClass} onClick={() => handleChooseClick('WHATSAPP')}>
                                <WhatsAppIcon style={{ width: 80, height: 80, color: 'gray' }} />
                                <p>WhatsApp</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <div className={classes.dialogueActions}>
                <DialogActions style={{ padding: "10px 25px" }}>
                    <Button
                        onClick={() => handleClose(false)}
                        className={classes.footerButton}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </div>
        </Dialog >
    );
};

export default withRouter(ChooseTemplates);
