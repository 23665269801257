import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, Grid, TextField } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { connect } from "react-redux";
import { useSnackbar } from 'notistack';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles((theme) => ({
    root: {

    },
    autoClass: {
        '& .MuiFormControl-root label': {
            top: '1px !important'
        },
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    autocompleteRoot1: {
        borderRadius: 4,
        padding: '7px !important',
        overflow: "clip",
        whiteSpace: "nowrap",
        "& .MuiAutocomplete-tagSizeSmall": {
            width: "65px !important",
        },
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
}));

const ElementOrderingDialog = ({ handleDialog, open, setLoading, selectedElement, objectElementsList = [], handleElementSortingCreate, handleElementSortingUpdate, sortingElements = [], ...other }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [details, setDetails] = useState({ element: null, display_name: '' });

    const elementsList = objectElementsList.filter(obj => !sortingElements.map(ele => ele.element?._id).includes(obj._id))

    useEffect(() => {
        selectedElement &&
            setDetails({
                ...details,
                element: selectedElement?.element,
                display_name: selectedElement?.element.label || selectedElement?.element.name,
            })
    }, [selectedElement])

    const handleChangeAutocomplete = (data, field) => {
        if (data.find(option => option.all)) setDetails({ ...details, [field]: (details.element && details.element.length === elementsList.length) ? [] : elementsList });
        else setDetails({ ...details, [field]: data });
    }
    const handleSubmit = () => {
        if (!details.element) {
            enqueueSnackbar('Please select Element', {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else {
            handleDialog();
            if (selectedElement) {
                let dubDetails = details.element
                dubDetails['index'] = selectedElement?.tableData.id
                dubDetails['label'] = details.display_name
                handleElementSortingUpdate(dubDetails);
            }
            else handleElementSortingCreate(details);
        }
    }

    return (
        <Dialog
            fullWidth="xs"
            maxWidth="xs"
            aria-labelledby="details-creation"
            open={open}
            onClose={() => handleDialog(false)}
            {...other}
        >
            <DialogTitle disableTypography className={classes.title}>
                {selectedElement ? 'Update Element' : 'Add Element'}
            </DialogTitle>
            <DialogContent style={{ padding: 24 }} className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.autoClass}>
                        {selectedElement ?
                            <Autocomplete
                                id="element"
                                options={elementsList}
                                getOptionLabel={(option) => option.name || ''}
                                value={details.element ? details.element : null}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label='Element' variant="outlined" required />}
                                onChange={(...args) => setDetails({ ...details, element: (args[1]) })}
                            />
                            :
                            <Autocomplete
                                id="groupTrans"
                                multiple
                                size="small"
                                options={elementsList}
                                limitTags={3}
                                disableCloseOnSelect
                                getOptionLabel={option => (option?.name || '')}
                                getOptionSelected={(option, value) => option?.element_id === value?.element_id}
                                classes={{ inputRoot: classes.autocompleteRoot1 }}
                                value={details?.element ? details?.element : []}
                                filterOptions={(options, params) => { // <<<--- inject the Select All option
                                    const filter = createFilterOptions()
                                    const filtered = filter(options, params)
                                    return elementsList.length && [{ name: 'Select All', all: true }, ...filtered]
                                }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            className={classes.checkClass}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            // checked={selected}
                                            checked={option.all ? !!(details?.element?.length === elementsList?.length) : selected}
                                            color="primary"
                                        />
                                        {option?.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        label="Element"
                                        size="small"
                                        required
                                    />
                                }
                                onChange={(...args) => handleChangeAutocomplete(args[1], 'element')}
                            />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {selectedElement &&
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="table-display-name"
                                value={details?.display_name ? details?.display_name : ''}
                                label="Dispaly Name"
                                onChange={(e) => setDetails({ ...details, display_name: e.target.value })}
                            />
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 25 }}>
                <Button variant='outlined' onClick={() => handleDialog(false)}>
                    Cancel
                </Button>
                <Button disabled={!details} variant='contained' style={{ marginLeft: 30 }} onClick={() => handleSubmit()} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default connect(null)(ElementOrderingDialog);
