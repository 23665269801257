import React, { useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, } from "@material-ui/core";
import { Close, Height } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import classNames from "classnames";
import { connect } from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        }
    },
    dialogTitle: {
        fontSize: 15,
        color: "#262626",
        fontWeight: 600,
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 10,
    },
    tableRoot: {
        width: '100%',
        marginTop: 0,
        padding: 0,
        '& .MuiToolbar-root': {
            height: '0px !important',
            marginBottom: '0px !important'
        }
    },
    dialogPaper: {
        minWidth: '50vw',
        borderRadius: 7,
        margin: 0,
        '& .MuiDialogTitle-root': {
            padding: '15px 18px !important',
        },
        '&  .MuiDialogContent-dividers': {
            padding: '16px 16px  0px 16px !important'
        }
    }
}));

const SettingsTablePreview = ({ open = false, handleClose, sortingElements, details, no_of_records }) => {
    const classes = useStyles();
    const [columns, setColumns] = useState([]);


    const recordsData = [
        { 'Transactions': '1' },
        { 'Transactions': '2' },
        { 'Transactions': '3' },
        { 'Transactions': '4' },
        { 'Transactions': '5' },
    ];

    useEffect(() => {
        const uniqueColumns = [
            ...new Set(sortingElements.map(item => item.element.label || item.elementName))
        ].map(name => ({
            title: name,
            field: name,
            emptyValue: '--',
            cellStyle: { minWidth: 120 }
        }));
        // Add the first column for Transactions
        setColumns([{ title: 'Sl No', field: 'Transactions' }, ...uniqueColumns]);
    }, [sortingElements]);


    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            width='md'
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {details?.display_name ? details?.display_name : details?.name}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <div className={classNames("material-table", classes.tableRoot)}>
                        <MaterialTable
                            title="List Of Elements"
                            columns={columns}
                            data={recordsData}
                            options={{
                                actionsColumnIndex: -1,
                                showTitle: false,
                                addRowPosition: 'first',
                                pageSizeOptions: false,
                                emptyRowsWhenPaging: false,
                                search: false,
                                pageSize: 5
                            }}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};


export default withRouter(connect(null)(SettingsTablePreview));