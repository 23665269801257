import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { MASTER_TEMPLATES_LIST, MASTER_TEMPLATE_ATTRIBUTES, MASTER_TEMPLATE_UPDATE } from "../../config";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "25px",
        width: "100%",
        "& .MuiPickersModal-withAdditionalAction": {
            display: "inline-flex !important",
        },
    },
    mentionInputRoot: {
        position: 'relative',
        '& .mention-input__suggestions': {
            zIndex: '10 !important'
        },
        '& .mention-input__highlighter': {
            display: 'none',
            minHeight: '112px !important',
        },
        '& .mention-input__input': {
            border: '1px solid #0000003b !important',
            borderRadius: '3px !important',
            minHeight: '112px !important',
            fontSize: '13px !important',
            width: '100% !important',
            position: 'initial !important'
        }
    },
    dialogTitle: {
        fontSize: 18,
        color: "#262626",
    },
    closeButton: {
        position: "absolute",
        right: 10,
        top: 12,
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: "5px 7px !important",
        height: 42,
    },
    dialogueActions: {
        // display: "flex",
        // justifyContent: "center",
        backgroundColor: "none",
        marginTop: 15,
    },
    footerButton: {
        width: 220,
        height: 40,
    },
    customDateLabel: {
        width: "100%",
        "& .MuiFormControl-root": { width: "100% !important" },
    },
    sheduleDate: {
        marginBottom: 10,
    },
    nudgeRef: {

    },
    dialogContent: {
        // minHeight: 430,
        '& .MuiInputLabel-outlined': {
            // top: '-6px !important',
            fontSize: 14
        }
    },
    dialogPaper: {
        width: '100%'
    }
}));



const WhatsappTemplateDialog = ({
    open = false,
    handleClose,
    selectedTemplate,
    setLoading,
    categoryList,
    editType = null,
    autoFocus = null,
    readOnly = false,
    industries,
    functions,
    whatsAppEvents,
    languageList
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [details, setDetails] = useState({
        industry: null,
        function: null,
        event: null,
        category: null,
        language: null,
        title: '',
        display_name: '',
        messageBody: '',
        messageHeader: '',
        messageFooter: '',
        messageaddbutton: '',
        urllinked: ''
    });
    const [categoryAttributes, setCategoryAttributes] = useState()
    const [whatsAppAttributes, setWhatsAppAttributes] = useState([]);
    const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] = useState()

    useEffect(() => {
        if (details?.category) {
            details?.industry && details.function && getCategoryAttributes()
        };
    }, [details?.category]);

    useEffect(() => {
        if (selectedTemplate) {
            Axios.get(MASTER_TEMPLATE_UPDATE(selectedTemplate?._id)).then(response => {
                let resData = response.data
                setSelectedWhatsappTemplate(resData);
                const templateDetails = {
                    ...details,
                    industry: resData?.industry_id ? industries.find((d) => d._id == resData.industry_id) : '',
                    function: resData?.function_id ? functions.find((d) => d._id == resData.function_id) : '',
                    category: resData?.category_id ? categoryList.find((d) => d._id == resData.category_id) : '',
                    event: resData?.event_type ? whatsAppEvents?.find((d) => d._id == resData?.event_type) : '',
                    language: resData?.language ? languageList.find((d) => d._id == resData.language) : null,
                    title: resData?.title,
                    display_name: resData?.display_name,
                    messageBody: resData?.body,
                    messageHeader: resData?.header,
                    messageFooter: resData?.footer_text,
                    messageaddbutton: resData?.footer_buttons ? resData?.footer_buttons[0]?.text : '',
                    urllinked: resData?.footer_buttons ? resData?.footer_buttons[0]?.url : ''
                };
                setDetails({ ...templateDetails });
                // setWhatsAppAttributes(resData?.attributes)
                setLoading(false);
            })
                .catch(error => {
                });
        }
    }, [selectedTemplate]);


    //-----get attribute lists -----
    const getCategoryAttributes = () => {
        setLoading(true);
        Axios
            .get(MASTER_TEMPLATE_ATTRIBUTES(details?.category?._id), { params: { industry_id: details?.industry?._id, function_id: details?.function?._id, page: 0, limit: 500 } })
            .then(response => {
                const data = response.data.map((el) => {
                    return {
                        name: el?.attribute_name,
                        id: el?._id,
                        ...el
                    }
                })
                setCategoryAttributes(data);
                const resWhatsappAttrs = selectedWhatsappTemplate.attributes.map(el => (
                    {
                        id: el.id,
                        attribute: data.find(att => att.attribute_key === el.key)
                    }
                ))
                setWhatsAppAttributes(resWhatsappAttrs)
                setLoading(false);
            })
            .catch(error => {
            });
    }


    const handleChangeInput = (e, field) => {
        if (field === 'messageBody') {
            const inputElement = e.target;
            const currentCursorPosition = inputElement.selectionStart; // Get the current cursor position
            let newMessageBody = e.target.value;
            if (newMessageBody.trim() === "") {
                setDetails({ ...details, [field]: newMessageBody });
                setWhatsAppAttributes([]); // Clear all attributes
                return;
            }
            //  if the user typed `{{` at the end of the input
            if (newMessageBody.endsWith('{{')) {
                const nextId = whatsAppAttributes.length + 1; // Determine the next placeholder ID
                newMessageBody = newMessageBody.slice(0, -2) + `{{${nextId}}}`; // Replace `{{` with `{{nextId}}`
                setWhatsAppAttributes((prev) => [...prev, { id: nextId, attribute: null }]); // Add new placeholder
                setDetails({ ...details, [field]: newMessageBody });
                // Restore cursor position to after the inserted placeholder
                setTimeout(() => {
                    const newCursorPosition = currentCursorPosition + `{{${nextId}}}`.length - 2; // Move cursor to after placeholder
                    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
                }, 0);
                return;
            }
            //  if the user is in the process of deleting a partial placeholder
            const beforeCursor = newMessageBody.slice(0, currentCursorPosition);
            const isDeletingPartialPlaceholder = beforeCursor.endsWith('{{') || beforeCursor.match(/{{\d+$/);
            if (isDeletingPartialPlaceholder) {
                setDetails({ ...details, [field]: newMessageBody });
                setWhatsAppAttributes((prev) =>
                    prev.filter(attr => newMessageBody.includes(`{{${attr.id}}}`))
                );
                return;
            }
            // existing placeholders
            const placeholderRegex = /{{(\d+)?}}/g;
            const placeholders = newMessageBody.match(placeholderRegex);
            if (placeholders) {
                let idCounter = 1; // Start ID sequence
                newMessageBody = newMessageBody.replace(placeholderRegex, () => `{{${idCounter++}}}`);
                // Update `whatsAppAttributes` based on normalized placeholders
                const updatedAttributes = Array.from({ length: idCounter - 1 }, (_, idx) => {
                    const existingAttribute = whatsAppAttributes.find(attr => attr.id === idx + 1);
                    return existingAttribute || { id: idx + 1, attribute: null };
                });
                setWhatsAppAttributes(updatedAttributes);
            }
            //Update the `details.messageBody` state
            setDetails({ ...details, [field]: newMessageBody });
            // Restore cursor position after state update
            setTimeout(() => {
                inputElement.setSelectionRange(currentCursorPosition, currentCursorPosition);
            }, 0);
        }
        else {
            setDetails({ ...details, [field]: e.target.value });
        }
    };

    // const handleChangeInput = (e, field) => {
    //     if (field === 'messageBody') {
    //         const newMessageBody = e.target.value;
    //         setDetails({ ...details, [field]: newMessageBody });
    //         const values = newMessageBody.match(/{{(\d+)}}/g);
    //         if (values) {
    //             // Extract IDs from the placeholders in messageBody
    //             const extracted = values.map((val) => {
    //                 const id = parseInt(val.replace(/[{{}}]/g, '')); // Extract the number
    //                 const existingAttribute = whatsAppAttributes.find(attr => attr.id === id);
    //                 return existingAttribute || { id, attribute: null };
    //             });
    //             setWhatsAppAttributes(extracted);
    //         }
    //         else setWhatsAppAttributes([]);
    //     }
    //     else setDetails({ ...details, [field]: e.target.value });
    // };


    const handleAttributeChange = (e, newValue, index) => {
        const updatedValues = [...whatsAppAttributes];
        updatedValues[index].attribute = newValue;
        setWhatsAppAttributes(updatedValues);
    };

    const handleSelect = (val, field) => {
        if (val) {
            if (field === 'industry') {
                setDetails({ ...details, [field]: val, function: null, event: null, category: null });
            }
            else if (field === 'function') {
                setDetails({ ...details, [field]: val, event: null, category: null });
            }
            else if (field === 'category') {
                setDetails({ ...details, [field]: val, });
                setWhatsAppAttributes(prevAttributes =>
                    prevAttributes.map(attr => ({ id: attr.id, attribute: null }))
                );
            }
            else {
                setDetails({ ...details, [field]: val, });
            }
        }
        else {
            setDetails({ ...details, category: null, event: null });
        }
    }


    const handleClearData = (value = false) => {
        handleClose(value);
    };

    // -------sumbit edit and post ----
    const handleSubmit = () => {
        setLoading(true);
        const messagePlaceholders = details.messageBody.match(/{{(\d+)}}/g);
        const missingAttributes = messagePlaceholders && messagePlaceholders.some((placeholder) => {
            const id = parseInt(placeholder.replace(/[{{}}]/g, ""));
            const attributeObject = whatsAppAttributes.find(attr => attr.id === id);
            return !attributeObject || !attributeObject.attribute; // Check if attribute is missing
        });
        if (
            details.title.trim() == "" ||
            details.category == null ||
            details.event == null ||
            details.function == null ||
            details.industry == null
        ) {
            enqueueSnackbar("Please enter required fields.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        }
        else if (details.messageaddbutton?.trim()?.length > 0 && (!details.urllinked?.trim())) {
            enqueueSnackbar("Please enter Valid URL/Link.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        }
        else if (details.urllinked?.trim()?.length > 0 && (!details.messageaddbutton?.trim())) {
            enqueueSnackbar("Please enter Button Text.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        }
        else if (missingAttributes) {
            enqueueSnackbar("Attributes for all placeholders in the message body are required.", {
                variant: "error",
                preventDuplicate: true,
            });
            setLoading(false);
        }
        else {
            let requestObj = {
                industry_id: details?.industry ? details.industry._id : null,
                function_id: details?.function ? details.function._id : null,
                category_id: details.category ? details.category._id : null,
                event_type: details.event ? details.event._id : null,
                title: details.title,
                attributes: whatsAppAttributes.length !== 0 ?
                    whatsAppAttributes.map(el => ({
                        id: el.id,
                        type: 'TEXT',
                        key: el.attribute?.attribute_key,
                        name: el.attribute?.attribute
                    })) : [],
                channel: 'WHATSAPP',
                language: details.language ? details.language._id : null,
                display_name: details.display_name,
                header: details.messageHeader,
                body: details.messageBody,
                footer_text: details.messageFooter,
                footer_buttons: (details.messageaddbutton?.trim()?.length > 0 || details.urllinked?.trim()?.length) > 0 ? [{
                    type: details.urllinked ? 'link' : null,
                    text: details.messageaddbutton ? details.messageaddbutton : null,
                    url: details.urllinked ? details.urllinked : null
                }] : []
            };
            setLoading(true);
            if (editType) {
                Axios.put(MASTER_TEMPLATE_UPDATE(selectedTemplate?._id), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Template Updated Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        enqueueSnackbar(
                            error.response && error.response.data
                                ? error.response.data.error_description
                                : "Failed to Update Template.",
                            {
                                variant: "error",
                                preventDuplicate: true,
                            }
                        );
                    });
            } else {
                Axios.post(MASTER_TEMPLATES_LIST(), requestObj)
                    .then((response) => {
                        setLoading(false);
                        handleClose(true);
                        enqueueSnackbar("Template Created Successfully.", {
                            variant: "Success",
                            preventDuplicate: true,
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        enqueueSnackbar(
                            error.response && error.response.data
                                ? error.response.data.error_description
                                : "Failed to add Template.",
                            {
                                variant: "error",
                                preventDuplicate: true,
                            }
                        );
                    });
            }
        }
    };

    return (
        <Dialog
            scroll={"paper"}
            classes={{ paper: classes.dialogPaper }}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle
                disableTypography
                onClose={() => handleClose(false)}
                className={classes.dialogTitle}
            >
                {editType ? 'Update WhatsApp Template' : 'Create WhatsApp Template'}
                <IconButton
                    size="small"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => handleClose(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="industry"
                                autoComplete
                                options={industries}
                                getOptionLabel={(option) => option.name}
                                value={details?.industry}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Industry"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'industry')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="function"
                                autoComplete
                                options={functions}
                                getOptionLabel={(option) => option.name}
                                value={details?.function}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Function"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'function')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{ maxLength: 512 }}
                                variant="outlined"
                                id="template-name"
                                value={details.title}
                                type="String"
                                label="Template Name"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "title")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="category"
                                autoComplete
                                options={categoryList}
                                getOptionLabel={(option) => option.name}
                                value={details?.category}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'category')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="event"
                                autoComplete
                                options={whatsAppEvents ? whatsAppEvents : []}
                                getOptionLabel={(option) => option.name}
                                value={details?.event}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Event"
                                        variant="outlined"
                                        required
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'event')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="template-name"
                                value={details.display_name}
                                type="String"
                                label="Template DisplayName"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "display_name")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="language"
                                autoComplete
                                options={languageList}
                                getOptionLabel={(option) => option.name}
                                value={details.language}
                                type="Schema"
                                clearable
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Language"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(e, val) => handleSelect(val, 'language')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="message-header"
                                value={details.messageHeader}
                                type="String"
                                label="Message Header"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "messageHeader")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={6}
                                inputProps={{ maxLength: 1024 }}
                                variant="outlined"
                                id="message-body"
                                value={details.messageBody}
                                type="String"
                                label="Message Body"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "messageBody")}
                            />
                        </Grid>
                        {details.category && details.messageBody && whatsAppAttributes?.length > 0 &&
                            <Grid item xs={12}>
                                <div className={classes.sheduleDate}>
                                    <label style={{ fontSize: 13, fontWeight: 'bold' }}> Define Attributes:</label>
                                </div>
                                {whatsAppAttributes.map((value, index) => (
                                    <Grid container key={index} spacing={2} alignItems="center" style={{ marginLeft: 8 }}>
                                        <span>{value.id}.</span>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                id="attribute"
                                                autoComplete
                                                options={categoryAttributes}
                                                getOptionLabel={(option) => option.name}
                                                value={value?.attribute}
                                                type="Schema"
                                                clearable
                                                classes={{ inputRoot: classes.autocompleteRoot }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Attribute"
                                                        variant="outlined"
                                                        required
                                                    />
                                                )}
                                                onChange={(e, newValue) => handleAttributeChange(e, newValue, index)}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                                id="message-footer"
                                value={details.messageFooter}
                                type="String"
                                label="Message Footer"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "messageFooter")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="add-button"
                                inputProps={{ maxLength: 25 }}
                                value={details.messageaddbutton}
                                type="String"
                                label="Button Text"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "messageaddbutton")}
                                required={details.messageaddbutton?.length || details.urllinked?.length ? true : false}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                inputProps={{ maxLength: 2000 }}
                                variant="outlined"
                                id="url-linked"
                                value={details.urllinked}
                                type="String"
                                label="URL Linked Button"
                                autoComplete="off"
                                onChange={(e) => handleChangeInput(e, "urllinked")}
                                required={details.urllinked?.length || details.messageaddbutton?.length ? true : false}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <div className={classes.dialogueActions}>
                <DialogActions style={{ padding: "10px 25px" }}>
                    <Button
                        onClick={() => handleClearData(false)}
                        className={classes.footerButton}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleSubmit()}
                        className={classes.footerButton}
                        variant="contained"
                        color="primary"
                    >
                        {editType ? 'Save' : 'Submit'}
                    </Button>
                </DialogActions>
            </div>
        </Dialog >
    );
};

export default withRouter(WhatsappTemplateDialog);
